import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'

import App from './App';
import { store } from './store'

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

const root = createRoot(document.getElementById("root"))

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>
);
